import { ILopChangeHocSinhClassRequest } from "../models/request/dm-lop/ILopChangeHocSinhClassRequest";
import { ILopChangeThongTuRequest } from "../models/request/dm-lop/ILopChangeThongTuRequest";
import { ILopChangeThuTuHocSinhRequest } from "../models/request/dm-lop/ILopChangeThuTuHocSinhRequest";
import { ILopChuNhiemRequest } from "../models/request/dm-lop/ILopChuNhiemRequest";
import { ILopHanhChinhUpdateSoDiemRequest } from "../models/request/dm-lop/ILopHanhChinhUpdateSoDiemRequest";
import { ILopLockParentAppHocSinhRequest } from "../models/request/dm-lop/ILopLockParentAppHocSinhRequest";
import { ILopRemoveHocSinhClassRequest } from "../models/request/dm-lop/ILopRemoveHocSinhClassRequest";
import { ILopSelectHocSinhRequest } from "../models/request/dm-lop/ILopSelectHocSinhRequest";
import { ILopSelectRequest } from "../models/request/dm-lop/ILopSelectRequest";
import { ILopImportPhanLopRequest } from "../models/request/dm-lop/LopImportPhanLopRequest";
import { IHocSinhSelectRequest } from "../models/request/hoc-sinh/IHocSinhSelectRequest";
import { dm_lop } from "../models/response/dm-lop/dm_lop";
import { apiClient } from "./apiClient";
import { BaseCRUDApi } from "./baseCRUDApi";

export const API_LOP_PATH = "lop";
class DMLopApi extends BaseCRUDApi<dm_lop> {
    constructor({ path }: { path: string; }) {
        super(path);
    }
    Select(request: ILopSelectRequest) { return apiClient.post(`${this._path}/select`, request) }
    SelectByIds(ids: number[]) { return apiClient.post(`${this._path}/select-ids`, ids) }
    SelectLopChuNhiemAsync(request: ILopChuNhiemRequest) {
        return apiClient.post(`${this._path}/select-lop-chu-nhiem`, request)
    }
    SelectTreeView(request: ILopSelectRequest) {
        return apiClient.get(`${this._path}/nam_hoc/${request.nam_hoc}/truong/${request.dm_truong_id}/tree-view`)
    }
    SelectHocSinhByLopsAsync(request: ILopSelectHocSinhRequest) {
        return apiClient.post(`${this._path}/hoc-sinh`, request)
    }
    SelectHocSinhChuaPhanLopAsync(request: IHocSinhSelectRequest) {
        return apiClient.post(`${this._path}/hoc-sinh/chua-phan-lop`, request)
    }
    ChangeThuTuTheoHoTenABCAsync(dm_truong_id: number) {
        return apiClient.put(`${this._path}/hoc-sinh/truong/${dm_truong_id}/change-thutu-macdinh`)
    }
    ChangeThuTuHocSinhAsync(request: ILopChangeThuTuHocSinhRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/change-thutu`, request)
    }
    ChangeHocSinhClassAsync(request: ILopChangeHocSinhClassRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/change-class`, request)
    }
    RemoveHocSinhClassAsync(request: ILopRemoveHocSinhClassRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/remove-class`, request)
    }
    LoadFromExcel(request: FormData) {
        return apiClient.upload(`${this._path}/hoc-sinh/import/load-from-excel`, request)
    }
    SaveDataExcel(request: ILopImportPhanLopRequest) {
        return apiClient.post(`${this._path}/hoc-sinh/import/save-data-excel`, request)
    }
    ChangeThongTuAsync(request: ILopChangeThongTuRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/change-thongtu`, request)
    }
    UpdateDgdkHocTap(request: ILopHanhChinhUpdateSoDiemRequest) {
        return apiClient.post(`${this._path}/so-diem-nlpc/dinh-ky`, request)
    }
    UpdateDgtxHocTap(request: ILopHanhChinhUpdateSoDiemRequest) {
        return apiClient.post(`${this._path}/so-diem-nlpc/thuong-xuyen`, request)
    }
    LockParentApp(request: ILopLockParentAppHocSinhRequest) {
        return apiClient.put(`${this._path}/hoc-sinh/lock-parent-app`, request)
    }
    SelectPhuHuynh(request: ILopSelectHocSinhRequest) {
        return apiClient.post(`${this._path}/phu-huynh`, request)

    }
    LoadLopFromExcel(request: FormData) {
        return apiClient.upload(`${this._path}/import/load-lop-from-excel`, request)
    }
    SaveDataLopExcel(request: ILopImportPhanLopRequest) {
        return apiClient.post(`${this._path}/import/save-data-lop-excel`, request)
    }
}
export const lopApi = new DMLopApi({ path: API_LOP_PATH });