import { LazyPage } from '../../components/loadable';

export const MonHocPage = LazyPage(import('./sis_monhoc'))
export const ToKhoiPage = LazyPage(import('./sis_tokhoi'));
export const ConfigPage = LazyPage(import('./config'));
export const GiaoVienPage = LazyPage(import('./sis_giaovien'))
export const TuanTietWrapper = LazyPage(import('./pl_tuan_tiet_wrapper'));
export const PLNamHocPage = LazyPage(import('./pl_namhoc'));
export const LoaiPhongHocWrapper = LazyPage(import('./pl_loaiphonghoc_wrapper'));
export const DmNhanXetPage = LazyPage(import('./sis_dm_nhanxet'));
export const MealPage = LazyPage(import('./sis_meal'));